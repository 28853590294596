import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import UIComponentMixin from "../UIComponentMixin";

// @vue/component
export default {
  name: "UiOneCheckbox",
  components: {
    PuxTranslate,
  },
  mixins: [
    UIComponentMixin,
  ],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    statusChecked() {
      return this.model === true;
    },
  },
  methods: {
    toggleModel($event) {
      if (this.isDisabled) {
        return;
      }
      setTimeout(() => {
        const MODEL = !this.statusChecked;
        this.input({
          model: MODEL,
          id: this.options.id,
          $event: $event,
          options: this.options,
        });
        this.change({
          model: MODEL,
          id: this.options.id,
          options: this.options,
          $event: $event,
        });
      });
    },
  },
};
